import React from 'react'
import { Navbar, Container } from 'reactstrap'
import Logo from './logo'

const LoginNav = () => {
  return (
    <Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit backgroundBlack">
      <Container>
        {/* <LogoText /> */}
        <div className="d-flex">
          <Logo width={150} />
          <h1 className="logo-header" style={{ color: '#fff' }}>
            Winery Portal
          </h1>
        </div>
      </Container>
    </Navbar>
  )
}

export default LoginNav
