import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import { useAuth0 } from '@auth0/auth0-react'
import { navigate } from 'gatsby-link';
import { StoreData, CheckToken, requestGetWithKey } from '../helpers/utils'
import { useLocation } from "@reach/router"
import LoginNav from '../components/loginnav'
import Footer from '../components/footer'

const Welcome = () => {
  const windowGlobal = typeof window !== 'undefined' && window
  const localStorageVar = windowGlobal ? localStorage : null
  const { isAuthenticated, user, getAccessTokenSilently, logout, loginWithRedirect } = useAuth0()
  const errCode = new URLSearchParams(useLocation().search).get('errorCode');
  useEffect(() => {
    if (CheckToken()) {
      var menus = []
      let permissionMenu = ['admin', 'campaigns', 'orders', 'contacts', 'my-profile']
      const getPermissons = [`api/profile/permissions`]
      requestGetWithKey(getPermissons, true).then(permissionsResult => {
        let permissionsUserHas = permissionsResult.message.map(x => x.codeName);
        menus = permissionMenu.filter(x => permissionsUserHas.includes(x))
        let redirectPage
        if (menus.includes('campaigns')) {
          redirectPage = '/campaigns'
        } else if (menus.includes('orders')) {
          redirectPage = `/orders`
        } else if (menus.includes('contacts')) {
          redirectPage = `/contacts`
        } else if (menus.includes('my-profile')) {
          redirectPage = `/my-profile`
        } else if (menus.includes('admin')) {
          redirectPage = `/admin/wineries`;
        } else {
          redirectPage = `/404`
        }
        localStorageVar.setItem('menu', JSON.stringify(menus))
        if (localStorageVar.getItem('menu')) {
          navigate(redirectPage)
        }
      })
        .catch((error) => {
          if (error.message === 'Network Error') {
            localStorageVar.clear()
            logout({ returnTo: window.location.origin + '?errorCode=backend_unreachable' })
          }
          else if (error.response?.status === 403 && (error.response.data.message === 'User does not have a group assigned')) {
            localStorageVar.clear()
            logout({ returnTo: window.location.origin + '?errorCode=user_does_not_have_permissions' })
          }
          else if (error.response?.status === 403 && error.response.data.message === 'User does not exist') {
            localStorageVar.clear()
            logout({ returnTo: window.location.origin + '?errorCode=user_does_not_exist' })
          }
        })
    }
    else {
      localStorageVar.clear()
    }

    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: process.env.GATSBY_AUTH0_SCOPE,
      })
        .then(token => {
          StoreData({ token })
        })
        .catch(error => { })
      StoreData(user)
    }
  })

  const renderResponse = (code) => {
    switch (code) {
      case 'backend_unreachable':
        return (
          <CardBody className="fs--1 font-weight-normal p-5 card-height">
            <h1 className="logo-header-text mb-4">Oops, we're having server issues.</h1>
            <p className="logo-content-text">
              Contact your Account Manager <br />
              if this persists.
            </p>
          </CardBody >
        )
      case 'user_does_not_have_permissions':
      case 'user_does_not_exist':
        return (
          <CardBody className="fs--1 font-weight-normal p-5 card-height">
            <h1 className="logo-header-text mb-4">Oops!</h1>
            <p className="logo-content-text">
              Contact your Account Manager <br />
              if this persists, or…
            </p>
            <Button onClick={loginWithRedirect} className="btn-vino btn-vino-primary mt-4">
              Try again
            </Button>
          </CardBody >
        )
      default:
        return (
          <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Winery Portal - Welcome</title>
          </Helmet>
          <CardBody className="fs--1 font-weight-normal p-5 card-height">
            <h1 className="logo-header-text mb-4">WELCOME</h1>
            <p className="logo-content-text">
              Elevating Winery contact info <br />
              to a superior quality dataset y'all!
            </p>
            <Button onClick={loginWithRedirect} className="btn-vino btn-vino-primary mt-4">
              Let's go!
            </Button>
            </CardBody >
            </>
        )
    }
  }


  return (
    <>
      <div className={errCode === 'backend_unreachable' ? "err503background" : "imgbackground"}>
        <LoginNav />
        <Row className="flex-center min-vh-100">
          <Col className="text-white text-center pt-1 paddingBottom">
            <Card style={{ background: 'transparent', boxShadow: 'none' }}>
              {renderResponse(errCode)}
            </Card>
          </Col>
        </Row>
        <footer className="footerBottom">
          <Footer />
        </footer>
      </div>
    </>
  )
}
export default Welcome
